import React, { useState, useEffect } from 'react';

import { Box, Center, Heading, Image, Text } from '@chakra-ui/react';
import { func, bool } from 'prop-types';

import WithAnimation from '@/components/Common/WithAnimation';
import useInvitation from '@hooks/useInvitation';
import useLang from '@hooks/useLang';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import ScrollToDown from '@/components/Common/ScrollToDown/V1';

import {
  ASSETS_FLOWER_LEFT,
  ASSETS_FLOWER_RIGHT,
  ASSETS_ANIMAL,
  BG_COVER,
  IMG_LOGO,
} from '@/constants/assets';
import { BUTTON_PADDING_PROPS } from '@/constants/colors';
import { THE_BRIDE } from '@/constants';

import txt from './locales';
import { styCover } from './styles';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 }
 */
function Cover({ loaded, onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const isInvitation = useInvitation();
  const lang = useLang();

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    setShowTitle(true);
  }, []);

  return (
    <Box
      minHeight="100vh"
      bgColor="bgPrimary"
      bgImage={`url(${BG_COVER})`}
      bgSize="cover"
      bgPos="center"
      className={styCover}
      position="relative"
    >
      <Image src={ASSETS_FLOWER_LEFT} className="flower__left" />
      <Image src={ASSETS_FLOWER_RIGHT} className="flower__right" />
      <Image src={ASSETS_ANIMAL} className="flower__animal" />
      <Box bgColor="rgb(0 0 0 / 10%)" height="100vh" width="100%">
        <Center width="100%" zIndex="2" height="90vh">
          <Box width="85%" position="relative" height="100%">
            {showTitle && (
              <Box
                color="mainColorText"
                letterSpacing="1px"
                fontWeight="400"
                fontSize="10px"
                width="100%"
                maxW="500px"
                padding="24px"
                bgSize="cover"
                borderRadius="16px"
                position="absolute"
                bottom="120px"
              >
                <Box textAlign="center" color="mainColorText">
                  <WithAnimation>
                    <Image
                      src={IMG_LOGO}
                      height="120px"
                      display="block"
                      margin="0 auto 24px auto"
                    />
                  </WithAnimation>
                  {/* BRIDE SECTION */}
                  <WithAnimation>
                    <Text zIndex="99" fontSize="16px" letterSpacing="2px">
                      {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
                    </Text>
                  </WithAnimation>
                  <WithAnimation>
                    <Heading
                      margin="16px 0"
                      fontWeight="normal"
                      size="xl"
                      zIndex="99"
                      fontFamily="CustomFont"
                      textTransform="uppercase"
                    >
                      {THE_BRIDE.replace('&', '+')}
                    </Heading>
                  </WithAnimation>
                </Box>
                <WithAnimation>
                  <ScrollToDown
                    loaded={loaded}
                    loading={loading}
                    marginTop="8px"
                    text={isInvitation ? txt.invitation[lang] : txt.announcement[lang]}
                    zIndex="99"
                    {...BUTTON_PADDING_PROPS}
                    size="md"
                    boxShadow="md"
                    onClick={handleClickDetail}
                  />
                </WithAnimation>
              </Box>
            )}
          </Box>
        </Center>
      </Box>
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
