import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Doa & Ucapan', `Prayers & Wishes`),
  desc: get(
    `Silakan kirimkan doa dan ucapan <br />yang tulus untuk kami:`,
    `Please leave your sincere prayers <br />and wishes to us and our families:`,
  ),
  name: get('Nama Anda', 'Your Name'),
  wish: get('Doa & Ucapan', 'Prayers & Wishes'),
  send: get('Kirim', 'Send Wishes'),
  success: get('Sukses!', 'Success!'),
  successMessage: get(
    `Terima kasih atas doa dan ucapanya!`,
    'Thank you for your Prayers and Wishes!',
  ),
  failedMessage: get(
    `Terjadi kesalahan. Silahkan muat ulang browser Anda atau coba lagi nanti.`,
    `Something wrong happened. Please try again or refresh the browser`,
  ),
  requiredField: get('Wajib diisi!', 'This field is required!'),
};
