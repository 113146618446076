import React from 'react';
import { number, string } from 'prop-types';
import { Box, Text } from '@chakra-ui/react';
import { NAVIGATION_COLOR } from '@/constants/colors';

function CountItem({ number, text }) {
  return (
    <Box
      margin="4px"
      padding="8px 4px 12px 4px"
      width="48px"
      height="64px"
      bgColor="bgPrimary"
      textAlign="center"
      color="mainColorText"
      border="1px solid"
      borderColor={NAVIGATION_COLOR}
    >
      <Box>
        <Text fontSize="lg" fontFamily="body">
          {number}
        </Text>
      </Box>
      <Box>
        <Text fontSize="sm" marginTop="-6px">
          {text}
        </Text>
      </Box>
    </Box>
  );
}

CountItem.propTypes = {
  number: number.isRequired,
  text: string.isRequired,
};

export default React.memo(CountItem);
