import { css } from '@emotion/css';

export const styCover = css`
  .flower {
    &__left {
      position: absolute;
      top: 120px;
      left: -24px;
      height: 250px;
      animation: flowerAnimation 5s ease-in-out -4s infinite alternate;
    }

    &__right {
      position: absolute;
      bottom: 100px;
      right: 0;
      height: 250px;
      animation: MoveUpDown 5s ease-in-out -4s infinite alternate;
    }

    &__animal {
      position: absolute;
      bottom: 100px;
      left: 60px;
      height: 150px;
    }
  }
`;
