import React from 'react';

import { string } from 'prop-types';
import { Box, Center } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

import CountDown from '@/components/Common/CountDown/V3';
import AddToCalendar from '@/components/Common/AddToCalendar';
import WithAnimation from '@/components/Common/WithAnimation';

import { BG_COUNTING_DOWN } from '@/constants/assets';
import { BUTTON_PADDING_PROPS } from '@/constants/colors';

import txt from './locales';

function CountingDown() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box
      bgColor="bgPrimary"
      padding="52px 24px 62px 24px"
      minHeight="250px"
      bgImage={`url(${BG_COUNTING_DOWN})`}
      bgSize="cover"
      bgPosition="top"
      position="relative"
    >
      <WithAnimation left>
        <Box width="100%" position="absolute" maxW="calc(500px - 64px)" bottom="-150px">
          <CountDown lang={lang} />
          {isInvitation && (
            <WithAnimation>
              <Center>
                <AddToCalendar text={txt.reminder[lang]} {...BUTTON_PADDING_PROPS} />
              </Center>
            </WithAnimation>
          )}
        </Box>
      </WithAnimation>
    </Box>
  );
}

CountingDown.propTypes = {
  lang: string.isRequired,
};

export default CountingDown;
