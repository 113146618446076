import React, { useState } from 'react';
import { string, arrayOf, object } from 'prop-types';
import { Box, useDisclosure } from '@chakra-ui/react';

import LazyImage from '../LazyImage';
import ImagesViever from '../ImagesViewer';
import WithAnimation from '../WithAnimation';

import './styles.css';

const getAdditionalProps = (index) => {
  if (index % 2 === 0) return { right: false };
  return { left: true };
};

/**
 * Function to render Image Hover
 * @param {Aray<string>} images - list of images
 * @returns {JSX.Element}
 */
function ImageHover({ images = [], imageProps, firstImageProps, ...rest }) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [firstIndex, setFirstIndex] = useState(0);

  const handleShowImage = (index) => {
    onOpen();
    setFirstIndex(index);
  };

  const addProps = (index) => {
    if (index === 0) {
      return typeof firstImageProps !== 'undefined' ? firstImageProps : {};
    }

    return {};
  };

  return (
    <Box {...rest}>
      {images.map((item, i) => {
        return (
          <WithAnimation key={`images-hover-${i}`} {...getAdditionalProps(i)}>
            <LazyImage
              src={item}
              onClick={() => handleShowImage(i)}
              objectFit="cover"
              objectPosition="center"
              height="250px"
              width="100%"
              cursor="pointer"
              {...imageProps}
              {...addProps(i)}
            />
          </WithAnimation>
        );
      })}
      {isOpen && <ImagesViever images={images} firstIndex={firstIndex} onClose={onClose} />}
    </Box>
  );
}

ImageHover.propTypes = {
  firstImageProps: object,
  images: arrayOf(string),
  imageProps: object,
};

ImageHover.defaultProps = {
  firstImageProps: undefined,
  images: [],
  imageProps: {},
};

export default ImageHover;
